/* Add crash game CSS here */
body {
  background-color: #000;
}
ul{
    list-style: none;
    padding: 0;
}
a{
    text-decoration: none;
}
.header{
    background: #0E1830;
    padding: 10px 15px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}
.modal-backdrop {
  z-index: 998 !important; 
}
.header-right input{
    border-radius: 7px;
    background: #3b455d;
    color: #fff;
    border: none;
    padding-left: 45px;
    height: 42px;
}
.header-right .form-control:focus{
    background: #3b455d;
    box-shadow: none;
    border-color: #2b7ffc;    
}
.header-right .form-control:focus{
    color: #fff;
}
.input-wrap{
    position: relative;
    margin-right: 15px;
}
.input-wrap img{
    position: absolute;
    left: 15px;
    top: 10px;
    width: 18px;
}
.cursor-pointer {
  cursor: pointer;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none;
}
.btn-primary{
    border-radius: 7px;
    background: #2b7ffc;
    border-color: #2b7ffc;
    padding: 8px 40px;
}
.btn-primary:disabled {
  cursor: auto !important;
}

.btn-secondary{
    border-radius: 7px;
    background: #20242d;
    border-color: #20242d;    
}

.brand-logo{
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    margin-left: 10px;
    font-size: 26px;
    font-style: italic;
}
.brand-logo:hover {
  color: #fff;
  text-decoration: none;
}
.subheader{
    background: #13161C;
    padding: 5px 15px;
    display: flex;
    justify-content: flex-end;
}
.subheader .btn-primary{
    padding: 8px 10px;
}
.theme-card{
    background: #191919;
    border:1px solid #172D5B;
    border-radius: 7px;
    padding: 15px;
}
.theme-card.game-wrap{
    padding: 0;
    overflow: hidden;
}
.main-wrap{
    padding: 15px;
    background: #000;
}
.result-btn-wrap .btn-primary, .result-btn-wrap .btn{
    padding: 4px 10px;
    border-radius: 7px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    margin: 10px 10px 10px 0;    
}

.round-bg {
  background: #191919;
  color: #fff;
  padding: 10px;
}

.change-position {
  position: relative;  
}
.result-position {
  position: absolute;
  bottom: 46px;
  background-color: #25272a;
  padding: 10px;
}

.btn-warning{
    background: #FC642B;
    border-color: #FC642B;
}
.btn-warning:hover{
    background: #df5826;
    border-color: #df5826;
}
.btn-success{
    background: #13A840;
    border-color: #13A840;
}

.result-btns-tags {
  overflow-x: hidden;
  white-space: nowrap;
}
::-webkit-scrollbar-track {
    background-color: #000;
}

::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius:20px;
}
.result-cta .refresh-btn img{
    width: 30px;
}
.result-cta .arrow-icon img{
    width: 20px;
}
.bet-btn-box {
  margin-bottom: 10px;
}
.btn.bet-btn, .btn.auto-bet-btn{
    border-radius: 30px 0px 0px 30px;
    background: #25272a;
    color: #FFFFFF !important;
    font-size: 12px;
    padding: 6px 20px;
}
.btn.auto-bet-btn{
    border-radius: 0px 30px 30px 0px;
}
.btn.bet-btn.active, .btn.auto-bet-btn.active{
     background: #1A3367;
}

.amount-input{
  width: fit-content;
    position: relative;
    margin-bottom: 10px;
    margin-right: 10px;
}
.amount-input input{
    background: #25272a;
    border-radius: 7px;
    height: auto;
    border-color: #25272a;
    font-weight: bold;
    padding-right: 63px;
    width:155px;
}
.form-control{
    color: #fff;
}
.input-cta{
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
}
.input-cta-left{
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
}
.input-cta img{
    width: 16px;
    cursor: pointer;
}
.input-cta-left img{
    width: 16px;
    cursor: pointer;
}
.form-control:focus{
    background: #25272a;
    box-shadow: none;
    border-color: #25272a;
    color: #fff;   
}
.amount-btn .btn{
    background: #25272a;
    color: #fff;
    border-radius: 7px;
    font-size: 11px;
}
.bet-lg-btn-wrap {
  width: 100%;
  max-width: 160px;
  min-width: 160px;
  margin-left: 10px;  
}
.bet-lg-btn{
  border-radius: 5px;
  background: #2B7FFC;
  color: #fff;
  box-shadow: 4px 4px 40px #0075ff7d;
  height: 86px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  border:1px solid  #2B7FFC;
  cursor: pointer;
}
button:focus{
    outline: none;
}
.bet-card-wrap{
    margin: 20px 0 0;
}
.card-remove-btn img{
    opacity: 0.4;
    width: 22px;
    margin-top: 10px;
}   
.card-remove-btn {
    z-index: 4;
}   
.bet-card-wrap .theme-card{
    height: 100%;
    padding: 10px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.button-grp {
  position: absolute;
  right: 10px;
  top: 3px;
}
.bet-card-wrap .col-md-4{
    padding:0 5px;
}
.card-add-btn {
  z-index: 4;
} 
.card-add-btn img{
    width: 22px;
    opacity: 0.4;
    margin-top: 10px;
}
.tab-section ul li{
    margin: 0 10px;
    white-space: nowrap;
    text-align: center;
}
.tab-section ul li a{
    color: #fff !important;    
    padding: 5px 10px;
    border-bottom: 2px solid  #3C465D;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;

}
.tab-section ul li a.active{
    border-bottom-color:#2B7FFC;
    color: #2B7FFC !important;
    font-weight: bold;
}
.top-bets-subtab{
    margin-top: 25px;
}
.top-bets-subtab li{
    margin: 0 5px;
}
.top-bets-subtab li a{
  color: #fff;
}
.top-bets-subtab li a:hover {
  text-decoration: none;
}
.top-bets-subtab li{
    padding:5px  15px;
    font-size: 14px;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
}
.top-bets-subtab li.active, .top-bets-subtab li:hover {
    background: #2B7FFC;
}
.win-badge.win-status{
    border:solid 1px #457f05;
    background: #123405;
    color: #fff;
    border-radius: 30px;
    padding: 2px 10px;
    margin-left: 5px;
}
.day-filter-wrap{
    margin-bottom: 10px;
}
.day-filter-wrap ul{
    border-radius: 30px;
    border: solid 1px #363738;
    margin: 0 auto;
    max-width: 200px;
}
.play-cash-card h4, .active-cash-card h4 {
  margin-left: 1%;
  font-size: 14px;
  margin: 3px;
}

.play-cash-card.selected,
.active-cash-card.selected {
  border-color: #229607;
}

.status-badge {
  min-width: 16px;
  height: 16px;
  background-color: #141516;
  border: 1px solid #38ca07;
  border-radius: 50%;
  margin: 2px
}

.selected .status-badge{
  background-color: #38ca07;
  border: #427f00 1px solid;
}
.day-filter-wrap a{
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 30px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    display: block;
}

.day-filter-wrap li.active a, .day-filter-wrap li:hover a{
 background: rgb(44, 45, 48);
}

.bet-details{
    color: #fff;
    margin: 10px 0;
}
.bet-details img{
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    height: 17px;
    max-height: 17px;
    min-height: 17px;
}
.user-details .user-img{
    height: 28px;
    width: 28px;
    border-radius: 9px;
    overflow: hidden;
    margin-right: 5px;
}
.user-details .user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
table{
    color: #fff;    
    border-collapse: separate;
    border-spacing: 0 10px;

}
 .table th, .table td{
    border-top: none;
    vertical-align: middle;
}
.table td{
    padding:8px 10px;
    border-bottom: 1px solid #2B7FFC;
    border-top: 1px solid #2B7FFC;
}
.table thead th{
    border:none;
    color: #2B7FFC;
    font-size: 14px;
    font-weight: 300;
    padding: 2px 10px;
}

tbody tr td:first-child{
    border-radius: 10px 0 0 10px;
}
tbody tr td:last-child{
    border-radius:0 10px 10px 0;
}
.total-bets-section, .total-bets-section .theme-card{
    height: 100%;
}
.bet-card-wrap .row{
    justify-content: center;
}

tbody tr{
    box-shadow: 0px 0px 0px 1px #172D5B;
    border-radius: 10px;
}
tbody tr td{
    background: #191919;
}
.tab-content-section .table-responsive{
    padding: 0 10px 0 10px;
}

.tab-content-section .custom-scrollbar {
    max-height: 750px;
}
.custom-scrollbar{
    max-height: 400px;
    overflow-y: auto;
}
#game-container canvas{
    width: 100% !important;
}
#game-container canvas{
    width: 100% !important;
}
.subheader{
    display: none;
}

.auto-play-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.auto-play-section > div {
  margin-top: 10px;
}
 
.auto-bet-wrapper .auto-bet {
    font-size: 12px;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
}

.auto-bet .auto-btn {
    border-radius: 7px;
    background-color: #1A3367;
    color: #fff;
    font-size: 13px;
    text-align: center;
    box-shadow: none;
    height: 22px;
    width: 80px;
    margin-right: 5px;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.auto-bet .auto-btn-stop {
  border: solid 1px #e90000;
  background-color: #600;
}
.on-off-switch p{
    margin: 0 5px 0;
    white-space: nowrap;
    font-size: 13px;
}
.on-off-switch{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  flex-grow: 1;
}
.on-off-switch input{
	display: block;
	opacity: 0;
    position: absolute;
}
.on-off-switch label{
	position: relative;
	width: 23px;
	height: 12px;
	display: inline-block;
	border:1px solid #2b7ffc;
	border-radius: 30px;
	cursor: pointer;
	transition: all 0.3s;
  white-space: nowrap;
  margin: 0;  
}

.auto-play-section .auto-bet-wrapper {
  flex-grow: 1;
}

.on-off-switch label:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 1px;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
  }
  input:checked + label{
    background: #2b7ffc;
  }
	

  .on-off-switch input:checked + label:after {
    left: auto;
    right: 2px;
    top: 1px;
  }
  .on-off-switch input:checked{
    background: red;
  }
  .auto-bet-modal .modal-header{
    background:#0E1830;
    padding: 3px 10px;
    color: #fff;
    border:none;
  }
  .auto-bet-modal .modal-header h5{
    font-size: 13px;
  }
  .auto-bet-modal .modal-content{
    border: none;
    background: #191919;
  }
  .auto-bet-modal .close{
    text-shadow: none;
    color: #fff;
  }
  .auto-bet-modal .modal-footer{
    border:none;
    padding: 5px;
    justify-content: center;
  }
  .auto-bet-modal .modal-footer .btn-primary{
    min-width: 150px;
    padding: 2px 10px;
    font-size: 13px;
    box-shadow: 4px 4px 40px #0075FF7D;
    cursor: pointer;
  }
  .auto-bet-modal .modal-footer .btn-secondary{
    padding: 2px 10px;
    font-size: 13px;
    cursor: pointer;
  }
  .round-listing li{
    border-radius: 5px;
    background: #30343a;
    padding: 5px;
    color: #fff;
    font-size: 13px;
    margin: 0 10px;
    min-width: 34px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .round-card{
    border-radius: 5px;
    background: #25272a;
    margin-bottom: 10px;
    padding: 10px;
    color: #fff;
  }
  .round-card h4{
    font-size: 14px;
    margin-bottom: 10px;
  }
  .round-listing .active {
    color: #fff;
    background-color: #545b62;

  }

  .large-modal {
    max-width: 800px !important;
  }

.cashout-section {
    font-size: 12px;
    text-align: center;
    display: flex;
    margin: auto;
    padding: 5px 0;
}
.playing-card{
    background: #25272a;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #fff;
    padding: 10px;
}
.playing-card .amount-input{
    margin: 0 5px 0;
}
.playing-card input{
    background: #30343a;
    height: 28px;
    padding-right: 43px;
    width: 100px;
    font-weight: normal;
    font-size: 12px;
}
.playing-card .form-control:focus{
    background: #30343a;
}
.playing-card .input-cta img{
    width: 12px;
}
.playing-card .input-cta {
    position: absolute;
    right: 5px;
    top: -1px;
}
.auto-cashout-input-container {
    /* margin: 6px 0px; */
    /* width: 50%; */
    /* padding: 5px 10px; */
}

.spinner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #232d3a;
    border: solid 1px #3C3C42;
    font-weight: 700;
    font-size: inherit;
    line-height: 1;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 70px;
}

.input input {
    width: 100%;
    border: 0;
    background: transparent;
    color: #fff;
    text-align: inherit;
    padding: 0;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .win-user-img{
    height: 40px;
    width: 40px;
    border-radius: 100%;
    overflow: hidden; 
  }
  .win-user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .wins-card {
    color: #fff;
    background: linear-gradient(82deg,#000000,#0f1214);
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .wins-card p{
    font-size: 12px;
    color: #7c7a7a;
    text-align: center;
  }
  .wins-card .user-name{
    margin: 0;
  }
  .win-card-right a .fa-info-circle{
    color: #fff;
  }
  .win-card-right a  .fa-check-circle{
    color: #28a909;
  }
  .multiplier-status{
    color: #900087;
    font-size: 12px;
    margin-left: 5px;
  }
  .cashout-badge{
    background: #900087;
    color: #e9c2e7;
    padding: 2px 10px;
    border-radius: 30px;
    margin-left: 5px;
    font-size: 12px;
  }
  .win-section .win-badge{
    margin-left: 5px;
    font-size: 12px;
  }
  .multiplier-card{
    background: linear-gradient(85deg,#000000,#0f1214);
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    font-size: 12px;
  }
  .multiplier-header{
    font-size: 12px;
    color: #7c7a7a;
    text-align: center;
    padding: 0 10px;
  }
  .fairness-content .fa-check-circle{
    color: #28a909;
    font-size: 14px;
  }
  .previous-hand{
    width: auto;
    height: 30px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b8b8b7;
    border-radius: 17px;
    border: solid 1px #6f6f6f;
    cursor: pointer;
    padding: 4px 8px 4px 5px;
  }
  .previous-hand.active{
    border: solid 1px #e90000;
    background-color: #600;
  }
  .previous-hand a{
    display: flex;
    margin-right: 3px;
  }

  /* Round Slider ends here */

  .active-row td {
    background: #1A2A4D;
  }

  .free-bet-row td {
    background: #123405;
    border-bottom: 1px solid #427F00;
    border-top: 1px solid #427F00;
  }

  .free-bet-row-modal td {
    background: #123405 !important;
    vertical-align: middle;
  }
  .bet-row-modal-demo {
    border-radius: 5px 0 0 5px;
    text-align: center;
  }
  .bet-row-modal-demo .free {
    width: 100%;
    background: #123405;
  }
  .bet-row-modal-demo .normal {
    width: 100%;
    background: #1A2A4D;
  }
  .free-bet-row-demo {
    width: 100%;
    background: #123405;
    border-bottom: 1px solid #427F00;
    border-top: 1px solid #427F00;
    border-radius: 5px 0 0 5px;
    text-align: center;
  }
  .active-row-demo {
    width: 100%;
    background: #1A2A4D;
    border-bottom: 1px solid #2B7FFC;
    border-top: 1px solid #2B7FFC;
    border-radius: 5px 0 0 5px;
    text-align: center;
  }
  .header .dropdown-toggle::after{
    display: none;
  }
  .header .dropdown-toggle, .header .btn-secondary:not(:disabled):not(.disabled):active, .header .show>.btn-secondary.dropdown-toggle{
    background: #2B7FFC;
  }
  .custom-dropdown-wrap{
    position: relative;
  }
  .header .custom-dropdown-menu{ 
    right: auto;
    left: -290px;
    top: 51px;
    position: absolute;
    background: #191919;
    border: 1px solid #2B7FFC;
    color: #fff;
    min-width: 333px;
    z-index: 10;
    border-radius: 7px;
    padding: 10px 0;
    display:none;
  } 
  .custom-dropdown-wrap.active .custom-dropdown-menu{
    display: block;
  }
  .account-user-name h4{
    font-size: 18px;
  }
  .account-user-img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
    border-radius: 9px;
    overflow: hidden;
  }
  .account-user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .account-user-name{
    padding: 0 10px;
  }
  .account-listing, .account-listing-bottom{
    margin: 10px 15px;
    padding: 0 10px;
    border-radius: 10px;
    background: #25272a;
  }
  .account-listing-bottom{
    background: transparent;
  }
  .account-listing li, .account-listing-bottom li{
    padding: 10px 0;
    border-bottom: 1px solid #3b3b3b;
  }
  .account-listing li:last-child, .account-listing-bottom li:last-child{
   border-bottom: none;
  }
  .listing-icon img{
    width: 20px;
  }
  .listing-icon{
    margin-right: 20px;
  }
  .account-listing-left h5{
    margin: 0;
    font-size: 13px;
    font-weight: normal;
  } 
  .account-listing-bottom li a{
    text-decoration: none;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
  }
  .menu-button-wrap button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px  auto;
    font-size: 11px;
  }
  .menu-button-wrap button:hover{
    background: #2b7ffc;
  }
  .menu-button-wrap button img{
    margin-right: 10px;
    width: 15px;
  }
  .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus, .btn-primary.focus, .btn-primary:focus, .btn-secondary.focus, .btn-secondary:focus{
    box-shadow: none;
  }
  .modal-wrap{
    padding: 10px;
  }
  .play-cash-card{
    border-radius: 5px;
    background: #25272a;
    color: #fff;
    padding: 10px;
    height: 48px;
    margin-bottom: 10px;
    border: 1px solid #141516;
  }
  .play-cash-card h4, .active-free-bet-section h4{
    margin:0;
    font-size: 14px;
  }
  .active-free-bet-section{
    margin-bottom: 20px;
  }
  .active-free-bet-section img{
    width: 20px;
    margin-right: 10px;
  }

  .play-cash-card span.active{
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 10px;
    background: red;    
  }
  .active-free-bet-section{
    color: #fff;
    font-size: 14px;
  }
  .active-free-bet-no-data img{
    width: 85px;
    margin: 10px auto;
  }
  .active-free-bet-no-data{
    text-align: center;
    margin-bottom: 20px;
  }
  .active-free-bet-no-data p{
    font-size: 13px;
    color: #FFF;
    text-align: center;
  }
  .provably-details{
    padding: 10px;
    border-bottom: 2px solid #26272A;
  }
  .next-client-card {
    padding: 10px;
    color: #fff;
  }
  .next-client-card h4{
    font-size: 13px;
    margin: 0;
  }
 
  .provably-details p,  .next-client-card p{
    font-size: 10px;
    color: #7A7A7A;
    margin: 0;
  }
  .modal-border-card-wrap{
    padding: 10px;    
  }

  .provable_fair_setting .modal-border-card-wrap {
    border: 1px solid #26272A;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .provable_fair_setting  .copy-section-manual {
    min-width: 159px;
    max-width: 159px;
    margin-right: 10px;
  }

  .manual-change button {
    font-size: 13px;
    font-weight: bold;
  }

  .opacity {
    opacity: 0.15 !important;
  }

  .division {
    width: calc(100% + 20px);
    background-color: rgba(38, 39, 42, 1);;
    height: 2px;
    display: inline-block;
    margin-left: -10px;
  }

  .manual-copy-section {
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;
  }

  .second-icon-text {
    margin-top: 15px;
  }

  .second-icon-text span {
    color: rgba(255, 255, 255, 1);
    font-size: 13px;
    margin-left: 10px;
  }

  .footer-body p {
    color: #7A7A7A;
    font-size: 10px;
    background-color: #26272A;
    padding: 17px 0;
    margin-bottom: 0;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: -10px;
    margin-top: 20px;
    border-radius: 0px 0px 5px 5px;
  }

  .manual-copy-section p {
    color: rgba(122, 122, 122, 1);
    font-size: 10px;
    margin-bottom: 0;
    margin-right: 15px;
  }


  /* bet history modal design css start here */

  .table-design {
    margin: 0 22px;
  }

  .table-design table {
    width: 100%;
    border-collapse: collapse;
  }
  .table-design table th {
    font-weight: normal;
    text-align: center;
    color: #7A7A7A;
    font-size: 14px;
    padding: 10px 0;
  }

  .table-design table tr {
    box-shadow: inherit; 
    border-radius: 0;
    text-align: center;    
    line-height: 39px;    
    border-bottom: 3px solid #191919;
    border-radius: 0;
    font-size: 13px;
  }
  .table-design table td {
    background-color: #1A2A4D;
    border-bottom: 1px solid #172D5B;
    border-radius: 0;
  }
  
  .load-more-btn {
    margin: 25px 0;
    text-align: center;    
    line-height: 6px;
  }

  .table-design table td span.info {
    background-color: #FC642B;
    color: #fff;
    font-size: 8px;
    display: flex;
    border-radius: 4px;
    line-height: 17px;
    width: fit-content;
    margin: 0 auto;
    padding: 0px 8px;
  }

  .table-design table td span.primary {
    background-color: #2B7FFC;
    color: #fff;
    font-size: 8px;
    display: flex;
    border-radius: 4px;
    line-height: 17px;
    width: fit-content;
    margin: 0 auto;
    padding: 0px 8px;
  }

  .table-design table td span.success {
    background-color: #13A840;
    color: #fff;
    font-size: 8px;
    display: flex;
    border-radius: 4px;
    line-height: 17px;
    width: fit-content;
    margin: 0 auto;
    padding: 0px 8px;
  }

  .table-design table tbody tr td:first-child {
    border-radius: 4px 0 0 4px;
  }

  .table-design table tbody tr td:last-child {
    border-radius: 0px 4px 4px 0px;
  }


  .load-more-btn button {
    color: #FFFFFF;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
  }
  /* bet history modal design css end here */

  /* game limit section css start here */
  .game-limit-section {
    padding: 22px;
  }
  .game-limit-section ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    border: 2px solid #26272A;
    border-radius: 7px;    
  }

  .game-limit-section ul li {    
    border-bottom: 2px solid #26272A;
    padding: 15px;
  }

  .game-limit-section ul li:last-child {    
    border: 0;
  }

  .game-limit-section ul li span {
    color: #FFFFFF;
    font-size: 13px;
  }

  .game-limit-section ul li span button {
    padding: 5px 17px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
  }
  /* game limit section css end here */

  /* game rules css start here */
    .game-rules-section p {
      margin-bottom: 0;
    }
    .game-rules-section {
      padding: 20px;
      display: inline-block;
      width: 100%;
    }

    .game-rules-section ul {
      margin-left: 20px;
    }

    .game-rules-section .first-section p:nth-child(1) {
      margin-bottom: 0;
      color: #7A7A7A;
      font-size: 13px;
      padding-bottom: 26px
    }

    .game-rules-section .first-section p.how-to-play {
      padding-bottom: 15px;
      color: #fff;
      font-size: 13px;
      margin-bottom: 0;
    }

    .game-rules-section .first-section a {
      color: #2377F4;
      font-size: 12px;
      padding-bottom: 26px;
      display: inline-block;
      width: 100%;
    }
    .second-para p {
      color: #FFFFFF;
      font-size: 13px;
      padding-top: 15px;
      padding-bottom: 150px;
    }
    
    .third-para p {
      font-size: 13px;
      color: #7a7a7a;
      padding-bottom: 30px;
    }
    .fourth-para .heading {
      font-size: 13px;
      color: #fff;
      padding-bottom: 10px;
    }
    .fourth-para ul li  {
      font-size: 13px;
      color: #7a7a7a;
      padding-bottom: 10px;
    }

    .game-function {
      font-size: 13px;
      color: #2B7FFC;
      padding-bottom: 10px;
    }
  /* game rules css end here */

  /* round history content css start here */
    .round-history-section p {
      margin-bottom: 0;
    }
    .round-history-section {
      display: inline-block;
      width: 100%;
      padding: 20px;
    }

    .round-history-section .round-section img {
      width: 26px;
      margin-right: 10px;
    }

    .round-history-section .round-section p.heading {
      color: #FFFFFF;
      font-size: 13px;
      padding-bottom: 4px;
    }

    .round-history-section .round-section p.paragraph{
      color: #7A7A7A;
      font-size: 10px;
    }
    .round-history-section .copy-section {
      margin-top: 8px;
      margin-bottom: 12px;
      text-align: center;
    }

    .round-history-section .copy-section p {
      font-size: 10px;
      color: #7a7a7a;
    }
    .round-table table {
      width: 100%;
    }

    .round-table table tr th {
      color: #7A7A7A;
      font-size: 10px;
      text-align: center;
      font-weight: normal;
    }

    .round-table table tr {
      box-shadow: inherit;
      border-radius: 0;
    }

    .round-table table tr td {
      color: #7A7A7A;
      font-size: 10px;
      text-align: center;
      background-color: #25272a;
      line-height: 24px;
      word-break: break-all;
    }
    tbody tr td:first-child {
      border-radius: 5px 0 0 5px;
    }

    tbody tr td:last-child {
      border-radius: 0 5px 5px 0;
    }
    .round-number {
      font-size: 13px;
      font-weight: normal;
    }
    .round-number span.tag {
      /* background-color: #FC642B; */
      color: #fff;
      font-size: 9px;
      margin: 0 10px;
      padding: 4px 9px;
      border-radius: 5px;
    }

  /* round history content css end here */
  
.custom-radion-btn{
    position: relative;
}
.custom-radion-btn label, .custom-radion-btn input:checked + label{
    background: transparent;
    color: #fff;
}
.custom-radion-btn label {
    padding-left: 28px;
    margin: 0;
    font-size: 10px;
}
.custom-radion-btn [type="radio"]:checked,
.custom-radion-btn [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.custom-radion-btn [type="radio"]:checked + .custom-radion-btn label,
[type="radio"]:not(:checked) +  label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #fff;
    margin: 0;
}
.custom-radion-btn [type="radio"]:checked + label:before,
.custom-radion-btn [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 14px;
    height: 14px;
    border: 1px solid #14CB00;
    border-radius: 100%;
}
.custom-radion-btn [type="radio"]:checked + label:before{
    top: 8px;
}

.custom-radion-btn [type="radio"]:checked + label:after,
.custom-radion-btn [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #14CB00;
    position: absolute;
    top: 7px;
    left: 3px;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.custom-radion-btn [type="radio"]:checked + label:after{
    top: 11px;
}

.custom-radion-btn [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.custom-radion-btn [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.copy-section label{
    font-size: 12px;
    color: #7A7A7A;
    margin: 0;
}
.copy-section .label-value{
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0 5px;
    max-width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 20px;
}
.copy-section a {
    color: #73767c;
} 
.copy-section{
    background: #25272a;
    padding: 8px 10px;
    border-radius: 5px;
}

.form-group .input-group input {
  background-color: unset;
  border: 1px solid #25272a;
}

.next-round-cancel-btn {
  display: grid;
  position: relative;
}

.next-round-cancel-btn span {
  color: #ffffffb3;
  font-size: 12px;
  text-align: center !important;
  display: block;
  line-height: 18px;
  margin-bottom: 5px;
  top: 0;
  left: 0px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 37px;
  padding-top: 15px;
}
.next-round-cancel-btn button{
  padding: 5px;
}

.error-msg{
  margin: 0;
}

.color-red {
  color: #fd5404 !important;
  font-size: 12px !important;
  text-align: left !important;
}

.main-err-wrapper{
  width: 100%;
  height: calc(100vh - 62px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
    background: #000;
}
.main-err-wrapper .previous-hand.active {
  width: auto;
  padding: 15px;
  font-size: large;
}
.active-cash-card{
  background-color: #141516;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
}

.active-cash-card.selected.active-free-bet {
  border: #427f00 1px solid;
}

.free-bet-card-text {
  background-color: #1b1c1d;
  border-radius: 7px;
  padding: 10px;
  font-size: 12px;
  color: gray;
  display: flex;
  justify-content: space-between;
}

.active-cash-card .row {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.freebet-button {
  margin-bottom: 5px;
  width: 100%;
  background-color: #1b1c1d;
  color: white;
  border-radius: 5px;
  padding: 10px;
  line-height: 6px;
}

.freebet-list-button {
  font-size: 11px;
  text-transform: uppercase;
  color: orange;
  border: 1px solid orange;
  border-radius: 10px;
  text-align: center;
  width: 90px;
  margin-left: 15px;
}

.freebet-button-lost {
  opacity: 0.5;
}
.freebet-badge {
  background: red;
  color: #fff;
  padding: 0px 5px 0px 4px;
  border-radius: 30px;
  margin-left: 15px;
  font-size: 9px;
  font-weight: 600;
}
.freebet-badge.burger {
  position:absolute;
  top: 0;
  right: 0;
}
.freebet-table-row {
  display: flex;
  justify-content: center;
  padding: 11px 0 !important;
  span {
    line-height: initial;
  }
}
.freebet-disclaimer {
  color: red;
  margin: 0;
}

.claim-container {
  background: #123405;
  border: 1px solid #427F00;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}

.claim-amount-box {
  border: 1px solid #ffffffb3;
  border-radius: 18px;
  padding: 5px 15px ;
}

.claim-container button {
  background-color: #d07206 !important;
  box-shadow: 4px 4px 40px #d07206;
  border-color: #d07206;
  border-radius: 18px;
  padding: 5px 15px;
  font-weight: 800;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 460.98px) {
  .active-cash-card .row {
    justify-content: center;
    margin: 3px 0;
  }
  .active-cash-card .row > div {
    width: 100%;
    text-align: center;
    margin: 3px 0;
  }
  .result-btn-wrap .btn-primary, .result-btn-wrap .btn {
    padding: 4px 8px;
    margin: 5px 5px 5px 0;
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

@media(max-width: 2000px){
    #game-container canvas{
        width: 100% !important;
    }
    #game-container{
        width: 100% !important;
    }
}
@media(max-width: 1800px){
    #game-container canvas{
        width: 100% !important;
    }
    #game-container{
        width: 100% !important;
    }
    .card-remove-btn img{
        right: 5px;
    }
}
@media(max-width: 1400px){
    #game-container canvas{
        width: 100% !important;
    }
    #game-container{
        width: 100% !important;
    }
    .tab-content-section .table{
        font-size: 14px;
        width: 500px;
        white-space: nowrap;
    }
}
@media(max-width: 1199px){
    #game-container canvas{
        width: 711px !important;
    }
    #game-container{
        width: 651px !important;
    }
    
}
@media(max-width: 1024px){
    #game-container canvas{
        width: 651px !important;
    }
    #game-container{
        width: 651px !important;
    }
    
    .tab-section ul li a{
        padding: 5px;
    }
    .bet-details{
        font-size: 14px;
    }
    .tab-content-section .table{
        width: 100%;
    }
}
@media(max-width: 991px){
    #game-container{
        width: 100% !important;
    }

    #game-container canvas{
        width: 100% !important;
    }
    .subheader{
        display: none;
    }
    .card-remove-btn img{
        right: 5px;
    }    
} 

.beta-div {
  text-align: end;
  color: #fff;
  font-size: 66%;
  font-weight: bold;
  position: absolute;
  top: 42px;
  left: 110px;
}
@media(max-width: 767.98px){
    #game-container{
        width: 100% !important;
    }
    .bet-card-wrap .col-md-6, .bet-card-wrap .col-md-12{
        padding: 0;
    }
    #game-container canvas{
        width: 100% !important;
    }
    .menu-icon img{
        width: 15px;
    }
    .brand-logo{
        font-size: 14px;
        margin-right: 10px;
    }
    .subheader, .header-right .btn-primary span{
        display: none;
    }
    .header-right  .btn-primary{
        padding: 4px 15px;
        line-height: 25px;
    }
    .btn-secondary{
        font-size: 14px;
    }
    .header-right .btn-primary img{
        margin-right: 0 !important;
    }
    .beta-div {
      font-size: 58%;
      font-weight: 500;
      top: 35px;
      left: 60px;
    }
    
}
@media(max-width:475px){
  .bet-lg-btn{
    width: 100%;
    font-size: 20px;
    min-height: 87px;
  }
  .btn-text-small {
    font-size: 17px;
    padding: 6px;
  }
}

@media screen and (max-width: 475px) {
  .bet-lg-btn-wrap {
    max-width: 130px;
    min-width: 130px;
    margin-left: 0px;
  }
  .top-bets-subtab li {
    padding: 5px;
    font-size: 12px;
  }
}


.relative-position {
  position: relative;
}

.position {
  position: absolute;
  top: 0px;
  left: 0px;
  will-change: transform;
  transform: translate(-190px, -7px);
  display: none;
}

.icon:hover + .position {
  display: block;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
}

.tooltip-inner ul {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  min-width: 160px;
}
.tooltip-inner ul li {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tooltip-inner ul li span {
  color: #b0bbc8;
  font-size: 12px;
}

.arrow {
  border: solid #000;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  right: -4px;
  top: 15px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.chat-box-wrap{
  max-width: 422px;
  margin-left: auto;
  position: fixed;
  bottom: 0;
  right:20px;
  width: 100%;
  height: 60px;
  z-index: 5;
}
.chat-box-wrap.active{
  height: auto;
}
.chat-box-header{
  background: #2B7FFC;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0px 0px;
}
.chat-header-left{
  display: flex;
  align-items: center;
}
.chat-header-left img{
  margin-right: 20px;
}
.chat-header-left h5{
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  margin: 0 ;
}
.language-wrap{
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  margin-bottom: 15px;
}
.language-wrap p{
  margin: 0 5px;
}
 
.chat-content-wrap{
  padding: 10px;
  background: #20457B;
}
.lang-arrow-icon {
  width: 10px;
}

.chat-card.self:before{
  left: inherit;
  right: -13px;
  transform: rotate(-45deg) translate(-1px, 0px);
  border-bottom-color: #142E54;
}

.chat-card.self.right-before:before, .chat-card.left-before:before {
  content: inherit;
}

.chat-card.self.right-before, .chat-card.left-before {
  border-radius: 5px !important;
}

.chat-card:before {
  position: absolute;
  content: '';
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;  
  border-bottom: 10px solid #142E54;
  top: -2px;
  left: -13px;
  transform: rotate(45deg) translate(0, 1px);
}

.chat-card{
  background: #142E54;
  border-radius: 0 5px 5px 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.chat-card.self {  
  background: #142E54;
  border-radius: 5px 0px 5px 5px;
}

.chat-card.self .chat-user-icon {
  margin: 0px 0px 0px 10px;

}
.chat-user-icon{
  height: 28px;
  width:28px;
  border-radius: 7px;
  margin-right: 10px;
}
.chat-user-icon img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-card p{
  margin: 0;
  color: #fff;
  font-size: 15px;
  /* word-break: break-all; */
}

.typing-section{
  padding: 10px;
}
.typing-section input{
  margin-right: 10px;
}
.typing-section .form-control:focus{
  background: #fff;
  border-color: #fff;
  color: #000;
}
.typing-section input{
  color: #000;
}
.chat-content-wrap .custom-scrollbar{
  max-height: 350px;
}
.bet-card-wrap ul{
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
} 
.bet-card-wrap ul li:first-child{ 
  margin-left: auto;
  margin-top: 0;
}
  
.bet-card-wrap ul li:last-child{
margin-right: auto;
  }
.bet-card-wrap ul li{
  margin: 0 5px;
}
.bet-card-wrap ul::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background-color: transparent;
}

.first-word-box {
  color: white;
  display: flex;
  justify-content: center;
  padding: 3px 9px;
}
.show-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.first-word-box-username {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 40px;
}
.extra-plus-desktop {
  width: 347px;
}
@media screen and (max-width: 991.98px) {
  .bet-card-wrap ul::-webkit-scrollbar {
    height: 2px;
    width: 2px;
    background-color: transparent;
  }
}
.bet-card-wrap ul::-webkit-scrollbar-thumb {
  background-color: #2B7FFC;
}
.card-add-btn{
  cursor: pointer;
}
.chat-box-wrap.active .arrow-icon{
  transform: rotate(180deg);
}

.counter-number {
  display: none;
}

@media(max-width:767.98px){
  .counter-section {
    position: relative;
  }
  
  .counter-number {
    display: block;
    position: absolute;
    top: -20px;
    left: -20px;
    font-size: 12px;
    font-weight: bold;
    background-color: #FC642B;
    color: #fff;
    padding: 5px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bet-card-wrap ul{
    flex-wrap: wrap;
    justify-content: center;
  }
  .bet-card-wrap ul li:first-child, .bet-card-wrap ul li:last-child{
    margin-left: inherit;
    margin-right: inherit;
  }
  .bet-card-wrap ul li{
    margin:10px 0;
  }
  .chat-header-left h5, .chat-box-header .arrow-icon{
    display: none;
  }
  .chat-box-wrap.active .chat-header-left h5, .chat-box-wrap.active .chat-box-header .arrow-icon{
    display: block;
  }
  .chat-box-wrap.active  .chat-box-header{
    width: 100%;
    border-radius: 20px 20px 0 0;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .chat-box-wrap.active  .chat-box-header .chat-header-left img{
    margin-right: 10px;
  }
  .chat-box-header{
    height: 60px;
    width: 60px;
    border-radius: 100%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .chat-header-left img{
    margin-right: 0;
    width: 25px;
  }
  .chat-box-wrap.active{
    max-width: 100%;
    right: 0px;
    margin: 0 10px;
    width: 300px;
  }
  .chat-box-wrap{
    bottom: 10px;
  }
  .extra-plus-desktop {
    display: none !important;
  }
  .card-add-btn img{
    opacity: 0.4;
    width: 16px;
    margin-left: 10px;
  }   
  .mentions {
    max-width: 51% !important;
    margin-right: 5px !important;
  }
}

#game-container {
  width: 100% !important;
  height: calc(100vh - 362px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991.98px) {
  .bet-card-wrap ul::-webkit-scrollbar {
    height: 2px;
    width: 2px;
    background-color: transparent;
  }    
  #game-container {
    width: 100% !important;
    height: calc(100vh - 125px) !important;
    max-height: 375px;
    display: flex;
    justify-content: center;  
  }
}

@media screen and (min-width: 575.98px) and (max-width: 991.98px) and (max-height: 575.98px){
  .bet-card-wrap {    
    -webkit-box-flex: 0;
    -ms-flex: 0 0 47%;
    flex: 0 0 47%;
    max-width: 47%;
    margin: 0px 0;
  }
  .bet-card-wrap ul li:first-child {
    margin-left: 0;
    margin-top: 0;
  }
    .bet-card-wrap ul li{
      margin-left: 0;
      margin-top: 10px;
      min-width: 350px
  }
  .main-wrap-screen {
    display: flex;
  }
  .main-wrap-screen .position-sticky{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 53%;
    flex: 0 0 53%;
    max-width: 53%;
  }
  .bet-card-wrap ul{
    display: block;
    max-height: calc(100vh - 95px) !important;;
  }
}
.graph-cta-wrap {
  background-color: #000;
}

.main-wrap-screen .position-sticky{
  position: sticky;
  top: 55px;
  left: 0;
  z-index: 5;
}

@media screen and (max-width: 575.98px) {
  #game-container {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    margin: 0 auto;
  }
  #game-container:before {
    content: "";
    display: block;
    padding-top: 60%;
  }
  .crash-graph-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    line-height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
#game-container canvas {
  width: 100% !important;
}
.crash-graph-wrap {
  width: 100%;
  height: 100%;
}

input:focus-visible {
  outline: 0 !important;
}

@keyframes blink {
  0% { background-color: #2b7ffc;}
  /* 50% { opacity: 0.5; }
   100% { opacity: 0.5; } */
}

.blink {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

.copied-div {
  color: #7A7A7A;
  font-size: small;
}

@media screen and (min-width: 992px) {
  .total-bets-section {
    height: calc(100vh - 96px) !important;
    overflow: hidden;
  }
  .total-bets-section .custom-scrollbar {
    height: calc(100vh - 311px) !important;
    max-height: unset !important;
  }
}

.css-nvf14r-ToastContainer {
  z-index: 10000 !important;
}
.css-1xhoubn-ToastContainer {
  z-index: 2001 !important;
}
.css-7206x3-ToastContainer {
  z-index: 2002 !important;
}

@media screen and (min-width: 768px) {
  .bet-card-wrap ul li:nth-last-child(2) .card-add-btn {
    display: none;
  }
}
.theme-card-border-cancel {
  border-color: #cb011a;
}
.theme-card-border-cancel .bet-lg-btn {
  background-color: #cb011a !important;
  box-shadow: 4px 4px 40px #cb011a;
  border-color: #cb011a;
  height: 86px;
  white-space: break-spaces;
  padding: 5px;

}
.theme-card-border-cashout {
  border-color: #d07206;
}
.theme-card-border-cashout .bet-lg-btn {
  background-color: #d07206 !important;
  box-shadow: 4px 4px 40px #d07206;
  border-color: #d07206;
  height: 86px;
  white-space: break-spaces;
  padding: 5px;
}

.mentions {
  max-width: 65%;
  margin-right: 8px;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__higlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 5px;
  border: 2px inset;
  height: 100%;
  border: none;
  /* background-color: #fff !important; */
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
  border: 1px solid silver;
}
.mentions--multiLine .mentions__highlighter {
  padding: 9px;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  min-height: 63px;
  outline: 0;
  border: 0;
}

.mentions__suggestions__list {
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #ced3e5;
  color: rgb(0, 0, 0);
}

.mentions__suggestions__list {
  max-height: 350px;
  overflow-y: auto;
}
.first-word-box-username {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 40px;
  }

.first-word-box-username-top {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 27px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.chat-user-name {
  display: block;
  font-size: 12px;
}

.bet-btn-wrap-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.view-detail-btn {
  width: 100%;
  height: 28px;
  background-color: #0E1830;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}
.view-detail-btn:hover {
  text-decoration: underline;
}

/* For Winning info toast CSS start*/
.pigeon-toast-container {
  position: relative;
  font-size: 12px;
  line-height: 12px;
}

.pigeon-toast-container-info {
  position: absolute;
  left: 0;
  right: 0;
  color: white;
  margin: 0 auto;
  display: flex;
  bottom: 10px;
  align-items: center;
  justify-content: center;
  animation-name: change-opacity;
  animation-duration: 2s
}

@keyframes change-opacity {
  0%   {opacity: 0;}
  25%  {opacity: 0;}
  50%  {opacity: 0;}
  51%  {opacity: 1;}
  100% {opacity: 1;}
}

.pigeon-toast-container-info .toast-winning-part{
  background-color: transparent
}
.toast-container {
  width: 265px;
  height: 64px;
  padding: 9px 2px;
  border-radius: 22px;
  border: 1px solid #427f00;
  color: #9ea0a3;
  font-size: 12px;
  line-height: 12px;
}
.toast-container-not-pigeon {
  display: flex;
  justify-content: space-evenly;
}
.toast-escape-container {
  font-size: 20px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  color: #fff;
  z-index: 3000;
}

.toast-winning-part {
  text-align: center;
  margin-left: 10px;
  color: #fff;
  font-weight: bolder;
  padding: 7px 10px;
  background-color: #4eaf11;
  border-radius: 20px;
  width: 79px;
  font-size: 13px;
}

.toast-cancel-btn {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
}

.toast-container {
  transition: transform 0.2s ease;
}

.toast-animation-entering {
  transform: translateY(20%);
}

.toast-animation-entered {
  transform: translateY(-20%);
}

.toast-animation-exiting {
  transform: translateY(20%);
}

.toast-animation-exited {
  transform: translateY(-20%);
  visibility: hidden;
}

.success-message-toast {
  font-size: 15px;
  padding: 4px;
  text-align: center;
  color: #fff;
  line-height: 17px;
}

.overlay {
  position: fixed;
  background-color: #000;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.phone {
  width: 120px;
  height: 240px;
  background-color: black;
  border: 4px solid white;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: rotatePhone 2.5s ease-in-out forwards;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
}
/* Notch */
.phone::before {
  content: '';
  width: 50px;
  height: 12px;
  background-color: black;
  border-radius: 10px;
  position: absolute;
  top: 4px;
  left: calc(50% - 25px);
}

.checkmark {
  position: absolute;
  left: 37%;
  display: inline-block;
  transform: rotate(45deg);
  height: 56px;
  width: 32px;
  border-bottom: 10px solid black;
  border-right: 10px solid black;
}
.fa-undo { 
  font-size: 100px;
  color: black;
  animation: iconUndo 2.5s ease-in-out forwards;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
}

@keyframes iconUndo {
  0% {
    color: white;
  }
  70% {
    color: #00ff00;
  }
  100% {
    color: #00ff00;
  }
  110% {
    color: #00ff00;
  }
}
@keyframes rotatePhone {
  0% {
    transform: rotate(90deg);
    background-color: black;
    border: 4px solid white;
  }
  70% {
    transform: rotate(0deg);
    background-color: #00ff00;
    border: 4px solid #177f0b;
  }
  100% {
    transform: rotate(0deg);
    background-color: #00ff00;
    border: 4px solid #177f0b;
  }
  110% {
    transform: rotate(0deg);
    background-color: #00ff00;
    border: 4px solid #177f0b;
 }
}


@media (orientation: landscape) and (max-height: 576px) and (max-width: 993px) {
  .overlay {
    visibility: visible;
  }
}

@media screen and (max-width: 460.98px) {
  .toast-container {
    width: 271px;
    height: 60px;
    font-size: 10px;
    line-height: 10px;
  }
  .pigeon-toast-container {
    font-size: 10px;
    line-height: 10px;
  }
  .toast-escape-container {
    font-size: 18px;
    line-height: 18px;
  }
  
  .toast-winning-part {
    font-size: 11px;
  }
  
  .toast-cancel-btn {
    font-size: 15px;
  }
}

/* For Winning info toast CSS End*/
